@tailwind base;
@tailwind components;
@tailwind utilities;

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.loader-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.834)
    url("https://media.giphy.com/media/8agqybiK5LW8qrG3vJ/giphy.gif") center
    no-repeat;
  z-index: 1;
}
