.body {  
  background-color: black;  
}  
#furioos_container {
    display: flex;
    align-items: center;
  }
  
.main-container {
    margin-top: 60px;
    display: flex;
    justify-content: center;
}

.fa-microphone {
    color: #14A76C;
}

.fa-microphone:hover {
    color: #0c6e47;
}

.fa-file-audio {
    color: #FF652F;
}

.fa-file-audio:hover {
    color: #d44916;
}

.output-display {
    background-color: #383b4b;
    height: 200px;
}

.fas, .fa {
    cursor: pointer;
}

.background {
    background-color: rgb(0, 0, 0);
    color: #747474;
}

/* Popup style */
.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
   
  .box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: rgb(38, 38, 38);
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  }
   
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }

  .popup-button {
    display: block;
    margin: 0 auto;
    width: 100px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #3f51b5;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }